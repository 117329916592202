import { useField, useFormikContext } from 'formik';
import { Fragment, useContext, useEffect, useState } from 'react';
import useSWR from 'swr';

import { Rule } from '../../../../../../../../typings/Ruleset.interface';
import APIMethodKeys from '../../../../../client/APIMethodKeys';
import HookdeckAPI from '../../../../../client/Hookdeck';
import { capitalizeFirstLetter, fieldName } from '../../../../../utils';
import {
  getMsIntervalByUnit,
  postprocessFormRules,
  preprocessFormRules,
  RETRY_STATEGY_LABELS,
  RULE_ORDER,
  RULE_TYPES,
  validateFormRules,
} from '../../../../../utils/rules';
import Button, { ClickableArea, StyledPlaceholderButton } from '../../../../common/base/Button';
import { StyledCard, StyledCardSection } from '../../../../common/base/Card';
import Divider from '../../../../common/base/Divider';
import Icon from '../../../../common/base/Icon';
import Link from '../../../../common/base/Link';
import Loading from '../../../../common/base/Loading';
import Tabs from '../../../../common/base/Tabs';
import Text from '../../../../common/base/Text';
import Tooltip from '../../../../common/base/Tooltip';
import Dropdown from '../../../../common/Dropdown';
import DropdownMenu from '../../../../common/DropdownMenu';
import EditorInput from '../../../../common/Form/Fields/EditorInput';
import IntervalInput from '../../../../common/Form/Fields/IntervalInput';
import TextInput from '../../../../common/Form/Fields/TextInput';
import { Div } from '../../../../common/helpers/StyledUtils';
import Search from '../../../../common/Search';
import { GlobalContext } from '../../../../contexts/GlobalContext';
import useLocalStorage from '../../../../hooks/useLocalStorage';
import useSearchQuery from '../../../../hooks/useSearchQuery';
import FilterRuleForm from './FilterRuleForm';
import TransformRuleFormWrapper from './TransformRuleForm';
import StyledEllipsisOverflow from '../../../../common/helpers/EllipsisOverflow';
import styled, { css } from 'styled-components';
import LabelButton from '../../../../common/base/LabelButton';

const StyledRulePlaceholderButton = styled(StyledPlaceholderButton)<{ active?: boolean }>(
  ({ theme, active }) => css`
    padding: ${theme.spacing(3)};
    display: flex;
    justify-content: space-between;
    ${active &&
    css`
      color: ${theme.colors.on.neutral.primary};
      &::after {
        border-color: ${theme.colors.on.neutral.primary};
      }
    `}
  `,
);

const rules_default = {
  retry: {
    strategy: 'linear',
    interval: 1000 * 60 * 60,
    count: 5,
  },
  transform: {
    input: JSON.stringify(
      {
        headers: {},
        body: {},
        query: '',
        path: '/',
      },
      null,
      2,
    ),
    transformation: {
      name: '',
      env: [],
      code: `addHandler('transform', (request, context) => {
  // Transform the request object then return it.
  return request;
});`,
    },
  },
  filter: {},
  delay: {
    delay: 1000 * 60,
  },
};

type Display = 'full' | 'compact';

const RetryRuleInputs: React.FC<{ name: string; onDelete: () => void }> = ({ name, onDelete }) => {
  const [{ value }, , { setValue }] = useField(fieldName('strategy', name));
  const [{ value: interval_value }] = useField(fieldName('interval', name));
  const [{ value: count_value }] = useField(fieldName('count', name));
  const [interval, unit] = getMsIntervalByUnit(interval_value);

  return (
    <StyledCard>
      <StyledCardSection p={{ y: 2, x: 3 }} flex={{ align: 'center', justify: 'space-between' }}>
        <Div flex={{ align: 'center' }}>
          <Icon left icon={RULE_TYPES['retry'].icon} />
          <Text subtitle>Retry</Text>
          <Tooltip
            tooltip={RULE_TYPES['retry'].description}
            cta={{
              label: 'Learn more',
              to: RULE_TYPES['retry'].docs,
            }}>
            <Icon right pointer icon="info" muted />
          </Tooltip>
        </Div>
        <Button.Permission small onClick={onDelete} icon="delete" minimal />
      </StyledCardSection>
      <StyledCardSection p={{ x: 3, y: 2 }}>
        <Div flex={{ align: 'center', wrap: true }}>
          <Text text_wrap={false} m={{ r: 1 }}>
            Retry
          </Text>
          <DropdownMenu.Permission
            options={Object.entries(RETRY_STATEGY_LABELS).map(([value, label]) => ({
              label,
              onClick: () => setValue(value),
            }))}
            renderToggle={(opened, toggle) => (
              <Link
                as="button"
                type="button"
                onClick={() => toggle(!opened)}
                style={{ display: 'inline-block' }}
                icon="arrow_drop_down">
                {RETRY_STATEGY_LABELS[value].toLowerCase()}
              </Link>
            )}
          />
          <Text text_wrap={false} m={{ x: 1 }}>
            every
          </Text>
          <Dropdown
            placement="bottom-start"
            renderToggle={(opened, toggle) => (
              <Link
                as="button"
                type="button"
                onClick={() => toggle(!opened)}
                icon="arrow_drop_down">
                {interval} {interval === 1 ? unit.slice(0, -1) : unit}
              </Link>
            )}>
            <Div p={3}>
              <IntervalInput
                m={{ left: 2, b: 0 }}
                name={fieldName('interval', name)}
                label="Interval"
                placeholder="1"
                help="Delay between retries"
                required
                min_interval="minutes"
              />
            </Div>
          </Dropdown>
          <Text text_wrap={false} m={{ x: 1 }}>
            up to
          </Text>
          <Dropdown
            renderToggle={(opened, toggle) => (
              <Link
                as="button"
                type="button"
                onClick={() => toggle(!opened)}
                primary
                icon="arrow_drop_down">
                {count_value} times
              </Link>
            )}>
            <Div p={3}>
              <TextInput
                name={fieldName('count', name)}
                label="Maximum Count"
                placeholder="10"
                type="number"
                max={50}
                min={1}
                required
                m={0}
              />
            </Div>
          </Dropdown>
        </Div>
      </StyledCardSection>
    </StyledCard>
  );
};

const filter_keys: string[] = ['body', 'headers', 'query', 'path'];
const FilterRuleInputs: React.FC<{
  name: string;
  onDelete: () => void;
  webhook_id?: string;
  source_id?: string;
}> = ({ name, onDelete, webhook_id, source_id }) => {
  const { query, updateSearchQuery } = useSearchQuery<{ filters: string }>();
  const [, { value, error }] = useField(name);
  const [selected_filter_key, setFilterKey] = useLocalStorage(
    'pref:connection:filter-rule:key',
    filter_keys && filter_keys[0],
  );

  return (
    <StyledCard overflow_hidden>
      <StyledCardSection p={{ y: 2, x: 3 }} flex={{ align: 'center', justify: 'space-between' }}>
        <Div flex={{ align: 'center' }}>
          <Icon left icon={RULE_TYPES['filter'].icon} />
          <Text subtitle>Filter</Text>
          <Tooltip
            tooltip={RULE_TYPES['filter'].description}
            cta={{
              label: 'Learn more',
              to: RULE_TYPES['filter'].docs,
            }}>
            <Icon right pointer icon="info" muted />
          </Tooltip>
        </Div>
        <Div flex={{ gap: 2 }}>
          <Button icon="edit" minimal small onClick={() => updateSearchQuery({ filters: name })}>
            Editor
          </Button>
          <Button.Permission small onClick={onDelete} icon="delete" minimal />
        </Div>
      </StyledCardSection>
      <StyledCardSection p={{ x: 3, t: 2 }}>
        <Tabs
          compact
          active_tab={selected_filter_key}
          tabs={filter_keys.map((key) => ({
            key: key,
            label: capitalizeFirstLetter(key),
            badge_label: value[key] ? '1' : undefined,
            badge_theme: error && error[key] ? 'danger' : 'muted',
          }))}
          onTabSelected={(key) => setFilterKey(key)}
          border={false}
          p={0}
        />
      </StyledCardSection>
      <StyledCardSection>
        <EditorInput.Permission
          key={selected_filter_key}
          format_on_blur
          display={'minimal'}
          name={fieldName(selected_filter_key, name)}
          height={`${6 * 16 + 16}px`}
        />
      </StyledCardSection>
      {query.filters === name && (
        <FilterRuleForm
          name={name}
          key={name}
          source_id={source_id}
          webhook_id={webhook_id}
          onClose={() => updateSearchQuery({}, { remove_keys: ['filters'] })}
        />
      )}
    </StyledCard>
  );
};

const TransformRuleInputs: React.FC<{
  name: string;
  onDelete: () => void;
  webhook_id?: string;
  source_id?: string;
  display: Display;
}> = ({ name, onDelete, webhook_id, source_id }) => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const [search_term, setSearchTerm] = useState('');
  const { values, resetForm, dirty } = useFormikContext<any>();
  const [, { value }, { setValue }] = useField(name);
  const { query, updateSearchQuery } = useSearchQuery<{
    transformation: string;
  }>();

  const { data: transformations } = useSWR(
    APIMethodKeys.transformations.list({
      name: search_term ? { contains: search_term } : undefined,
    }),
    () =>
      HookdeckAPI.transformations.list({
        name: search_term ? { contains: search_term } : undefined,
      }),
  );

  useEffect(() => {
    if (value.transformation_id) {
      HookdeckAPI.transformations.get(value.transformation_id).then((transformation) => {
        const new_value = {
          ...value,
          transformation: {
            name: transformation.name,
            code: transformation.code,
            env: Object.entries(transformation.env || {}),
          },
        };
        if (!dirty) {
          // Use resetForm instead of setValue to avoid making the form "dirty"
          const new_values = {
            ...values,
            rules: values.rules.map((rule) => (rule.type === 'transform' ? new_value : rule)),
          };
          resetForm({ values: new_values });
        } else {
          setValue(new_value);
        }
      });
    }
  }, [value.transformation_id]);

  const current_transformation_name = value.transformation?.name;

  const card = (
    <StyledCard>
      <StyledCardSection p={{ y: 2, x: 3 }} flex={{ align: 'center', justify: 'space-between' }}>
        <Div flex={{ align: 'center' }}>
          <Icon left icon={RULE_TYPES['transform'].icon} />
          <Text subtitle>Transform</Text>
          <Tooltip
            tooltip={RULE_TYPES['transform'].description}
            cta={{
              label: 'Learn more',
              to: RULE_TYPES['transform'].docs,
            }}>
            <Icon right pointer icon="info" muted />
          </Tooltip>
        </Div>
        <Button.Permission onClick={onDelete} small icon="delete" minimal />
      </StyledCardSection>
      {value.transformation?.name || value.transformation_id ? (
        <StyledCardSection
          p={{ y: 1, x: 3 }}
          flex={{ align: 'center', justify: 'space-between', gap: 2 }}>
          <StyledEllipsisOverflow>
            <Div flex={{ align: 'center' }}>
              <Icon icon={RULE_TYPES['transform'].icon} muted left />
              <Text ellipsis monospace>
                {value.transformation?.name ? current_transformation_name : <Loading />}
              </Text>
            </Div>
          </StyledEllipsisOverflow>
          <Div flex={{ align: 'center', gap: 2 }}>
            <Button
              small
              onClick={() => {
                updateSearchQuery({
                  transformation: name,
                });
              }}
              minimal
              disabled={!value.transformation}
              icon="edit">
              Editor
            </Button>
            <Button.Permission
              small
              onClick={() => setValue({ ...value, transformation_id: null, transformation: {} })}
              icon="sort"
              minimal
            />
          </Div>
        </StyledCardSection>
      ) : (
        <>
          <StyledCardSection p={3}>
            <Search
              name="transformation-search"
              value={search_term}
              onChange={setSearchTerm}
              placeholder="Search for a transformation..."
            />
            <Button
              m={{ t: 3 }}
              block
              icon="link"
              outline
              onClick={() => {
                let default_value = { type: 'transform', ...rules_default.transform };
                if (search_term) {
                  default_value = {
                    ...default_value,
                    transformation: {
                      ...default_value.transformation,
                      name: search_term,
                    },
                  };
                }
                setValue(default_value);
                updateSearchQuery({ transformation: name });
              }}>
              Create {search_term ? `"${search_term}"` : 'new'} transformation
            </Button>
          </StyledCardSection>
          <StyledCardSection scroll style={{ maxHeight: 400 }}>
            {transformations?.count === 0 && (
              <Text muted p={{ x: 3, y: 2 }}>
                {search_term
                  ? 'No transformation match that search term...'
                  : 'No existing transformations yet...'}
              </Text>
            )}
            {transformations?.models.map((transformation, i) => (
              <Fragment key={transformation.id}>
                {i !== 0 && <Divider />}
                <ClickableArea
                  flex={{ justify: 'space-between', align: 'center' }}
                  p={{ x: 3, y: 2 }}
                  onClick={() => {
                    setValue({
                      ...value,
                      transformation_id: transformation.id,
                      transformation: {
                        ...transformation,
                        env: Object.entries(transformation.env || {}),
                      },
                    });
                  }}>
                  <Div flex={{ align: 'center' }}>
                    <Icon icon={'transformation'} m={{ r: 3 }} />
                    <Text monospace>{transformation.name}</Text>
                  </Div>
                </ClickableArea>
              </Fragment>
            ))}
          </StyledCardSection>
        </>
      )}
    </StyledCard>
  );

  return (
    <>
      {card}
      {query.transformation === name && (
        <>
          {!value.transformation ? (
            <Loading />
          ) : (
            <TransformRuleFormWrapper
              key={name}
              webhook_id={webhook_id}
              source_id={source_id}
              transformation_id={value.transformation_id}
              transformation={value.transformation}
              onClose={() => {
                updateSearchQuery({
                  ...query,
                  transform_tab: undefined,
                  field: undefined,
                  selected_execution_id: undefined,
                  input_execution_id: undefined,
                  transformation: undefined,
                  transformation_id: undefined,
                });
              }}
              onSave={async (new_value) => {
                setValue({
                  ...value,
                  transformation: new_value.transformation,
                });
                return Promise.resolve(true);
              }}
            />
          )}
        </>
      )}
    </>
  );
};

const DelayRuleInputs: React.FC<{ name: string; onDelete: () => void }> = ({ name, onDelete }) => {
  const [{ value: delay }] = useField(fieldName('delay', name));
  const [delay_value, unit] = getMsIntervalByUnit(delay);
  return (
    <StyledCard>
      <StyledCardSection p={{ y: 2, x: 3 }} flex={{ align: 'center', justify: 'space-between' }}>
        <Div flex={{ align: 'center' }}>
          <Icon left icon={RULE_TYPES['delay'].icon} />
          <Text subtitle>Delay</Text>
          <Tooltip
            tooltip={RULE_TYPES['delay'].description}
            cta={{
              label: 'Learn more',
              to: RULE_TYPES['delay'].docs,
            }}>
            <Icon right pointer icon="info" muted />
          </Tooltip>
        </Div>
        <Button.Permission small onClick={onDelete} icon="delete" minimal />
      </StyledCardSection>
      <StyledCardSection p={{ x: 3, y: 2 }}>
        <Div flex={{ align: 'center', wrap: true }}>
          <Text text_wrap={false} m={{ r: 1 }}>
            Delay for
          </Text>
          <Dropdown
            parent_width={false}
            placement="bottom-start"
            renderToggle={(opened, toggle) => (
              <Link
                as="button"
                type="button"
                onClick={() => toggle(!opened)}
                icon="arrow_drop_down">
                {delay_value} {delay_value === 1 ? unit.slice(0, -1) : unit}
              </Link>
            )}>
            <Div p={3}>
              <IntervalInput
                m={{ left: 2, b: 0 }}
                name={fieldName('delay', name)}
                label="Delay"
                placeholder="0"
                required
              />
            </Div>
          </Dropdown>
        </Div>
      </StyledCardSection>
    </StyledCard>
  );
};

const COMPONENTS = {
  retry: RetryRuleInputs,
  filter: FilterRuleInputs,
  transform: TransformRuleInputs,
  delay: DelayRuleInputs,
};

export type RulesFormValues = Rule[];

const rule_form_props = {
  postprocessValues: (values: RulesFormValues) => {
    // deep copy the values so they don't change in the form
    values = JSON.parse(JSON.stringify(values));
    return postprocessFormRules(values);
  },
  getInitialValues: (rules?: Rule[]) =>
    rules ? preprocessFormRules(rules) : [{ type: 'retry', ...rules_default.retry }],
  validate: async (values: RulesFormValues, HookdeckAPI: HookdeckAPI) => {
    const rules_error = await validateFormRules(values, HookdeckAPI);
    if (rules_error) {
      return rules_error;
    }
    return {};
  },
  Fields: ({
    prefix,
    webhook_id,
    source_id,
    display,
  }: {
    prefix: string;
    webhook_id?: string;
    source_id?: string;
    display: 'full' | 'compact';
  }) => {
    const { validateField, submitCount } = useFormikContext();
    const { updateSearchQuery } = useSearchQuery<{ filters: Boolean }>();

    const [{ value: rules }, { error }, { setValue, setTouched }] = useField(prefix);
    const onSetValue = (v) => {
      setTouched(true);
      setValue(v);
      validateField(prefix);
    };

    const addType = (type) => {
      if (!rules.some((r) => r.type === type)) {
        onSetValue([...rules, { type, ...rules_default[type] }]);
        if (type === 'filter') {
          updateSearchQuery({ filters: true });
        }
      }
    };

    const removeType = (type) => {
      if (rules.some((r) => r.type === type)) {
        onSetValue(rules.filter((r) => r.type !== type));
      }
    };

    const remaining_rule = Object.entries(RULE_TYPES).filter(
      ([type]) => !rules?.some((r) => r.type === type),
    );
    return (
      <Div flex={{ direction: 'column', gap: 4 }}>
        {Object.keys(RULE_ORDER).map((key) => {
          const config = RULE_TYPES[key];
          const rule_index = rules.findIndex((r) => r.type === key);
          const rule = rules[rule_index];
          if (rule) {
            const Component = COMPONENTS[rule.type];
            return (
              <Div key={rule.type}>
                <Component
                  name={`${prefix}[${rule_index}]`}
                  onDelete={() => removeType(rule.type)}
                  webhook_id={webhook_id}
                  source_id={source_id}
                  display={display}
                />
                {error &&
                  error[rule_index] &&
                  (rule.type !== 'transform' || submitCount > 0) &&
                  rule.type !== 'filter' && (
                    <Text m={{ top: 1.5, bottom: 0 }} as="p" danger>
                      {Object.values(error[rule_index])[0]}
                    </Text>
                  )}
              </Div>
            );
          }
          if (display === 'compact') {
            return;
          }
          const cta = config.docs
            ? {
                label: 'Learn more',
                to: config.docs,
              }
            : undefined;
          return (
            <StyledRulePlaceholderButton key={key} onClick={() => addType(key)}>
              <Div flex={{ align: 'center' }}>
                <Icon icon={config.icon} left />
                {config.label}
                <Div style={{ zIndex: 1 }}>
                  <Tooltip tooltip={config.description} cta={cta}>
                    <Icon right pointer icon="info" />
                  </Tooltip>
                </Div>
              </Div>
              <Icon icon="add" />
            </StyledRulePlaceholderButton>
          );
        })}
        {display === 'compact' && remaining_rule.length > 0 && (
          <Div flex={{ align: 'center', wrap: true, gap: 2 }} m={{ t: 0 }}>
            {remaining_rule
              .sort(([type_a], [type_b]) => RULE_ORDER[type_a] - RULE_ORDER[type_b])
              .map(([type, config]) => (
                <LabelButton.Permission
                  key={type}
                  onClick={() => addType(type)}
                  label={config.label}
                  label_icon={config.icon}
                  flex
                  neutral
                  icon="add"
                />
              ))}
          </Div>
        )}
      </Div>
    );
  },
};

export default rule_form_props;
