import { differenceInDays } from 'date-fns';
import { Formik } from 'formik';
import { useCallback, useContext, useState } from 'react';
import { Route, Switch as SwitchRoute, useHistory, useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';
import useSWR from 'swr';

import { Grid, GridUnit } from '@hookdeck/theme';
import { CardElement } from '@stripe/react-stripe-js';

import { organization_levels_by_role } from '../../../../../../../../domain/roles';
import { Subscription } from '../../../../../../../../typings/Subscription.interface';
import APIMethodKeys from '../../../../../client/APIMethodKeys';
import Sentry from '../../../../../client/Sentry';
import LINKS from '../../../../../configs/links';
import { OrbPlan } from '../../../../../typings/Orb.interface';
import { pluralize } from '../../../../../utils';
import { showChat } from '../../../../../utils/liveChat';
import { isFreePlan } from '../../../../../utils/subscription';
import Alert from '../../../../common/base/Alert';
import Badge from '../../../../common/base/Badge';
import Button from '../../../../common/base/Button';
import { StyledCard } from '../../../../common/base/Card';
import Container from '../../../../common/base/Container';
import Divider from '../../../../common/base/Divider';
import Icon from '../../../../common/base/Icon';
import Link from '../../../../common/base/Link';
import Loading from '../../../../common/base/Loading';
import Text from '../../../../common/base/Text';
import Tooltip from '../../../../common/base/Tooltip';
import { useDialog } from '../../../../common/Dialog';
import CheckboxInput from '../../../../common/Form/Fields/CheckboxInput';
import Switch from '../../../../common/Form/Fields/Switch';
import TextInput from '../../../../common/Form/Fields/TextInput';
import { Div } from '../../../../common/helpers/StyledUtils';
import Modal from '../../../../common/Modal';
import { useToasts } from '../../../../common/Toast';
import { GlobalContext } from '../../../../contexts/GlobalContext';
import { UserContext } from '../../../../contexts/UserContext';
import useSearchQuery from '../../../../hooks/useSearchQuery';
import { DashboardContext } from '../../DashboardContext';
import WrapWithStripeElement, { useStripeElementOptions } from './WrapWithStripeElement';

const CancelModal: React.FC = () => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const { mutateSubscription } = useContext(DashboardContext);
  const history = useHistory();
  const { addToast } = useToasts();

  const { data: subscription_details } = useSWR(
    APIMethodKeys.billing.getSubscriptionDetails(),
    () => HookdeckAPI.billing.getSubscriptionDetails(),
  );

  const onClose = () =>
    history.push({
      pathname: '/settings/organization/plans',
      state: { scroll: false },
    });

  const onCancel = () => {
    HookdeckAPI.billing
      .cancel()
      .then((subscription) => {
        mutateSubscription(subscription);
        history.push({
          pathname: '/settings/organization/billing',
          state: { scroll: false },
        });
        addToast(
          'success',
          `You will be downgraded to the free plan at the end of your billing period.`,
        );
      })
      .catch(() => {
        addToast('error', `Failed to cancel plan, please contact us.`);
      });
  };

  return (
    <Modal
      title="Cancel your plan"
      cancel_label="Cancel"
      submit_label="Confirm"
      onSubmit={onCancel}
      onCancel={onClose}
      onClose={onClose}>
      {!subscription_details ? (
        <Loading />
      ) : (
        <>
          <Text as="p">
            You will be <strong>downgraded to the free plan</strong> at the end of your billing
            period on{' '}
            <strong>
              {new Date(subscription_details.current_billing_period_end_date).toLocaleDateString(
                'en-US',
                {
                  weekday: undefined,
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                },
              )}
            </strong>
            .
          </Text>
          <Text as="p">
            <Link as="button" primary onClick={showChat}>
              Contact us
            </Link>{' '}
            if you need help.
          </Text>
        </>
      )}
    </Modal>
  );
};

const PaymentMethodModal: React.FC<{ handlePostUpgrade: (sub: Subscription) => void }> = ({
  handlePostUpgrade,
}) => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const { user } = useContext(UserContext);

  const { addToast } = useToasts();
  const history = useHistory();
  const { plan } = useParams<{ plan: string }>();
  const stripe_element_options = useStripeElementOptions();

  const onClose = useCallback(
    () =>
      history.push({
        pathname: '/settings/organization/plans',
        state: { scroll: false },
      }),
    [],
  );

  const handleSubmit = useCallback(
    async (elements, stripe, client_secret, values) => {
      try {
        const cardElement = elements.getElement(CardElement);
        if (cardElement == null) {
          throw new Error('Card element unavailable.');
        }
        const setup = await stripe.confirmCardSetup(client_secret, {
          payment_method: { card: cardElement },
        });
        const { error, setupIntent } = setup;
        if (error != null || setupIntent?.status !== 'succeeded') {
          throw error ?? new Error('Payment method could not be saved succesfully.');
        }

        await HookdeckAPI.billing.setPaymentMethodAsDefault(setupIntent.payment_method);
        await HookdeckAPI.billing.updateBillingEmail(values.email);
        // WARNING: This is a hack to make sure the payment method is saved before changing the plan, there seems to be a race condition
        await new Promise((resolve) => setTimeout(resolve, 2000));
        await HookdeckAPI.billing
          .changePlan(plan)
          // Retry a second time if it didn't work
          .catch(async () => {
            await new Promise((resolve) => setTimeout(resolve, 5000));
            return HookdeckAPI.billing.changePlan(plan);
          })
          .then((subscription) => {
            handlePostUpgrade(subscription);
          });
      } catch (err) {
        console.error(err);
        Sentry.captureException(err);
        addToast('error', 'Something went wrong, please try again or contact us.');
      }
    },
    [plan],
  );

  return (
    <WrapWithStripeElement
      loading={
        <Modal
          title="Set your payment method"
          cancel_label="Cancel"
          submit_label="Confirm & Pay"
          portal
          onCancel={onClose}
          onClose={onClose}>
          <Div flex={{ align: 'center', justify: 'center' }} p={{ t: 8, x: 8 }}>
            <Loading />
          </Div>
        </Modal>
      }>
      {({ stripe, elements, client_secret }) => (
        <Formik
          initialValues={{ email: user!.email, cc_complete: false }}
          onSubmit={(values) => handleSubmit(elements, stripe, client_secret, values)}
          validate={(values) => {
            const errors: any = {};
            if (!values.email || values.email.length < 1) {
              errors.email = 'Email is required';
            }
            if (!values.cc_complete) {
              errors.cc_complete = 'Credit card is required';
            }
            return errors;
          }}>
          {(props) => (
            <form onSubmit={props.handleSubmit}>
              <Modal
                title="Set your payment method"
                cancel_label="Cancel"
                submit_label="Confirm & Pay"
                onSubmit={props.isValid ? props.handleSubmit : undefined}
                onCancel={onClose}
                onClose={onClose}
                portal
                is_submitting={props.isSubmitting}>
                <Div m={{ b: 4 }}>
                  <Text as="p" size="s" subtitle m={{ b: 1 }}>
                    Credit Card
                    <Text danger as="span">
                      *
                    </Text>
                  </Text>
                  <StyledCard p={3}>
                    <CardElement
                      onChange={(event) => props.setFieldValue('cc_complete', event.complete)}
                      options={stripe_element_options}
                    />
                  </StyledCard>
                  {props.errors.cc_complete && (
                    <Text m={{ t: 1 }} danger>
                      {props.errors.cc_complete}
                    </Text>
                  )}
                </Div>
                <TextInput m={{ b: 0 }} name="email" type="email" label="Billing Email" required />
              </Modal>
            </form>
          )}
        </Formik>
      )}
    </WrapWithStripeElement>
  );
};

const FeatureCell: React.FC<{ text?: string | boolean; subtext?: string; billed?: boolean }> = ({
  text,
  subtext,
  billed = true,
}) => {
  return (
    <Div flex={{ direction: 'column', justify: 'space-between' }} h={100}>
      <Div>
        {text && (
          <>
            <Div flex={{ align: 'center' }}>
              <Icon primary={billed} muted={!billed} icon={billed ? 'check' : 'remove_done'} left />
              {typeof text === 'string' && <Text size="m">{text}</Text>}
            </Div>
            {subtext && (
              <Text m={{ l: 6 }} muted size="m">
                {subtext}
              </Text>
            )}
          </>
        )}
      </Div>

      <Divider m={{ t: 4 }} />
    </Div>
  );
};

const StyledFeatureRow = styled(Div)<{ hightlight: boolean }>`
  ${({ hightlight, theme }) =>
    hightlight &&
    css`
      position: relative;
      z-index: 0;
      &:before {
        content: '';
        z-index: -1;
        position: absolute;
        top: ${theme.spacing(2)};
        left: ${theme.spacing(-2)};
        right: ${theme.spacing(-2)};
        bottom: ${theme.spacing(2)};
        border-radius: ${theme.radius.normal};
        background-color: ${theme.colors.surface.container.primary};
      }
    `}
`;

type FeatureRowCell = { text: string | boolean; subtext?: string; billed?: boolean };
const FeatureRow: React.FC<{
  label: string;
  subtext?: string;
  hightlight: boolean;
  cells: (null | FeatureRowCell)[];
}> = ({ label, subtext, cells, hightlight }) => (
  <StyledFeatureRow hightlight={hightlight} p={{ t: 4 }}>
    <Grid gap={4}>
      <GridUnit size={1 / 4}>
        <Div flex={{ direction: 'column', justify: 'space-between' }} h={100}>
          <Text subtitle size="m">
            {label}
          </Text>
          {subtext && (
            <Text muted size="m">
              {subtext}
            </Text>
          )}
          <Divider m={{ t: 4 }} />
        </Div>
      </GridUnit>
      {cells.map((cell, i) => (
        <GridUnit size={1 / 4} key={i}>
          <FeatureCell text={cell?.text} subtext={cell?.subtext} billed={cell?.billed} />
        </GridUnit>
      ))}
    </Grid>
  </StyledFeatureRow>
);

const OrganizationPlans: React.FC = () => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const { subscription, mutateSubscription, organization_role } = useContext(DashboardContext);

  const isAuthorized =
    organization_levels_by_role[organization_role!] >= organization_levels_by_role.admin;

  const [show_legacy_plan, setShowLegacyPlan] = useState(true);
  const [isLoading, setIsLoading] = useState<string | null>(null);
  const history = useHistory();
  const { addToast } = useToasts();
  const showDialog = useDialog();
  const { query } = useSearchQuery<{ highlight: string }>();

  const highlighted_feature = query.highlight;

  const { data: plans } = useSWR(APIMethodKeys.billing.getPlans(), () =>
    HookdeckAPI.billing.getPlans(),
  );

  const formatted_plans = plans?.map((plan) => ({
    id: plan.external_plan_id,
    version: plan.version,
    name: plan.name,
    features: plan.metadata.features.length > 0 ? plan.metadata.features.split(',') : [],
    metered_prices: plan.prices.filter((price) => price.price_type === 'usage_price'),
    metadata: plan.metadata,
    fixed_price: Number(
      plan.prices.find(
        (price) => price.price_type === 'fixed_price' && price.fixed_price_quantity === 1,
      )?.unit_config?.unit_amount || 0,
    ),
  }));

  const { data: subscription_details } = useSWR(
    APIMethodKeys.billing.getSubscriptionDetails(),
    () => HookdeckAPI.billing.getSubscriptionDetails(),
  );

  const sorted_plans =
    formatted_plans && formatted_plans.sort((a, b) => a.fixed_price - b.fixed_price);

  const handlePostUpgrade = (new_subscription: Subscription) => {
    mutateSubscription(new_subscription);
    const new_plan = formatted_plans!.find((p) => p.name === new_subscription.plan);
    let upgrade_modal_fields;

    if (
      new_plan &&
      new_plan?.fixed_price >
        Number(
          subscription_details!.plan.prices.find((price) => price.price_type === 'fixed_price')
            ?.unit_config?.unit_amount,
        )
    ) {
      if (new_subscription.plan.indexOf('starter') === 0) {
        upgrade_modal_fields = () => (
          <>
            <Text as="p" m={{ b: 2 }}>
              What was the main reasons for your upgrade?
            </Text>
            <CheckboxInput
              name="reasons.retention"
              label={`Longer retention period of ${new_subscription.retention_days} days`}
            />
            <CheckboxInput
              name="reasons.usage_limit"
              label="Receive more then 100k request/events"
            />
            <CheckboxInput name="reasons.users" label="Add team members" />
            <CheckboxInput name="reasons.integrations" label="Enable integrations" />
          </>
        );
      } else if (new_subscription.plan.indexOf('growth') === 0) {
        upgrade_modal_fields = () => (
          <>
            <Text as="p" m={{ b: 2 }}>
              Thank you for upgrading. What was the main reasons for your upgrade?
            </Text>
            <CheckboxInput
              name="reasons.retention"
              label={`Longer retention period of ${new_subscription.retention_days} days`}
            />
            <CheckboxInput name="reasons.metrics" label={`Access to metrics and metrics export`} />
            <CheckboxInput
              name="reasons.roles"
              label={`Improved roles management with view-only role`}
            />
          </>
        );
      }
    }

    if (upgrade_modal_fields) {
      showDialog(
        (v: { reasons: Record<string, boolean> }) =>
          HookdeckAPI.track
            .event('Selected Upgrade Reasons', {
              reasons: v.reasons,
              plan: new_plan!.id,
            })
            .catch((err) => {
              Sentry.captureException(err);
            }),
        undefined,
        {
          title: `Plan upgraded to ${new_plan?.name}!`,
          submit_label: 'Submit',
          cancel_label: 'Close',
          form_props: {
            initial_values: { reasons: {} },
            Fields: upgrade_modal_fields,
          },
        },
      );
    } else {
      addToast('success', 'You have successfully subscribed to this plan.');
    }

    history.push(`/settings/organization/billing`);
  };

  const onPlanSelected = async (plan: string) => {
    if (isLoading) return;

    try {
      if (plan === 'free' && !isFreePlan(subscription!.plan)) {
        return history.push({
          pathname: `/settings/organization/plans/downgrade`,
          state: { scroll: false },
        });
      }
      setIsLoading(plan);
      const card = await HookdeckAPI.billing.getCard();
      if (card) {
        const new_subscription = await HookdeckAPI.billing.changePlan(plan);
        handlePostUpgrade(new_subscription);
      } else {
        setIsLoading(null);
        history.push({
          pathname: `/settings/organization/plans/${plan}`,
          state: { scroll: false },
        });
      }
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
      addToast('error', 'Something went wrong, please try again or contact us.');
      setIsLoading(null);
    }
  };

  let current_plan_index = sorted_plans?.findIndex(
    (p) =>
      (subscription?.plan && subscription?.plan.startsWith(p.id)) ||
      (subscription?.plan?.startsWith('business') && p.id.startsWith('team')),
  );

  if (subscription?.cancel_at && new Date(subscription.cancel_at).getTime() > Date.now()) {
    current_plan_index = 0;
  }

  const outdated_version =
    subscription_details &&
    sorted_plans &&
    !subscription?.cancel_at &&
    (current_plan_index! < 0 ||
      (current_plan_index! >= 0 &&
        sorted_plans &&
        sorted_plans[current_plan_index!].version !== subscription_details.plan.version));

  const is_enterprise_plan =
    subscription?.plan.indexOf('enterprise_') === 0 || subscription?.plan.indexOf('custom_') === 0;

  const getPricePerMillion = (
    package_with_allocation_config: OrbPlan['prices'][0]['package_with_allocation_config'],
  ) => {
    if (!package_with_allocation_config) return 0;

    const scale = 1_000_000 / Number(package_with_allocation_config.package_size);
    return Number(scale * Number(package_with_allocation_config.package_amount));
  };

  const billing_metrics = {
    requests: false,
    events: false,
    discarded_requests: false,
    attempts: false,
  };
  const getPlanMetric = (
    plan: any,
    metric: 'events' | 'requests' | 'discarded requests' | 'attempts',
  ) => {
    const is_free_plan = plan.fixed_price === 0;

    const prices = plan.metered_prices || plan.prices;
    const metered_price = prices.find((price) => price.name.toLowerCase() === metric.toLowerCase());
    const allocation =
      metered_price?.package_with_allocation_config?.allocation ||
      subscription?.max_requests ||
      plan.metadata?.max_requests ||
      10_000;
    const price = getPricePerMillion(metered_price?.package_with_allocation_config);

    if (!metered_price) {
      return {
        text: 'N/A',
        subtext: 'Not billed on this plan',
        billed: false,
      };
    }

    switch (metric) {
      case 'events':
        billing_metrics.events = true;
        break;
      case 'requests':
        billing_metrics.requests = true;
        break;
      case 'discarded requests':
        billing_metrics.discarded_requests = true;
        break;
      case 'attempts':
        billing_metrics.attempts = true;
        break;
    }

    return {
      text: `Includes ${Number(allocation).toLocaleString('en-US')}`,
      subtext: is_free_plan || !price ? 'Overage not available' : `+ $${price} per 1 million`,
      billed: !!price || !!allocation,
    };
  };

  const { plansEventsRow, plansDiscardedRequestRow, plansRequestsRow, plansAttemptsRow } = (
    sorted_plans || []
  ).reduce(
    (acc, orbPlan) => {
      const is_current_plan =
        (subscription_details?.plan?.external_plan_id &&
          subscription_details?.plan?.external_plan_id.startsWith(orbPlan.id)) ||
        (subscription_details?.plan?.external_plan_id &&
          subscription_details?.plan?.external_plan_id.startsWith('business') &&
          orbPlan.id.startsWith('team'));
      const plan = is_current_plan && show_legacy_plan ? subscription_details.plan : orbPlan;
      acc.plansEventsRow.push(getPlanMetric(plan, 'events'));
      acc.plansDiscardedRequestRow.push(getPlanMetric(plan, 'discarded requests'));
      acc.plansRequestsRow.push(getPlanMetric(plan, 'requests'));
      acc.plansAttemptsRow.push(getPlanMetric(plan, 'attempts'));

      return acc;
    },
    {
      plansEventsRow: [] as FeatureRowCell[],
      plansDiscardedRequestRow: [] as FeatureRowCell[],
      plansRequestsRow: [] as FeatureRowCell[],
      plansAttemptsRow: [] as FeatureRowCell[],
    },
  );
  const getPlanFeature = (plan_index: any, metadata: OrbPlan['metadata'], feature: string) => {
    const is_current_plan = current_plan_index === plan_index;
    return is_current_plan && show_legacy_plan
      ? subscription?.[feature] ||
          subscription_details?.plan?.metadata?.[feature] ||
          metadata[feature]
      : metadata[feature];
  };

  return (
    <Container large m={{ b: 24 }}>
      {isAuthorized && (
        <SwitchRoute>
          <Route path="/settings/organization/plans/downgrade" component={CancelModal} />
          <Route
            path="/settings/organization/plans/:plan"
            render={() => <PaymentMethodModal handlePostUpgrade={handlePostUpgrade} />}
          />
        </SwitchRoute>
      )}
      <Div m={{ b: outdated_version ? 4 : 10 }}>
        <Text heading size="xl" as="h1" m={{ t: 14, b: 1 }}>
          Choose your plan
        </Text>
        <Div m={{ b: 4 }} flex={{ justify: 'space-between' }}>
          <Text muted as="p" m={0}>
            Plans apply across every project in an organization. If you need help,{' '}
            <Link as="button" primary onClick={showChat}>
              contact us
            </Link>
          </Text>
          <Link href={LINKS.pricing} target="_blank" primary>
            Full plan breakdown {'->'}
          </Link>
        </Div>
        <Divider />
      </Div>
      {outdated_version && current_plan_index !== -1 && (
        <Div flex={{ gap: 2, align: 'center' }} m={{ b: 10 }}>
          <Switch
            checked={show_legacy_plan}
            onChange={(e) => setShowLegacyPlan(e.target.checked)}
          />
          <Text>Show legacy plan</Text>
          <Tooltip tooltip="We no longer offer the plan you're currently subscribed to. Hookdeck honors the usage and features offered by your legacy plan.">
            <Icon muted pointer icon="info" />
          </Tooltip>
        </Div>
      )}
      {is_enterprise_plan || current_plan_index === -1 ? (
        <Alert info inline>
          You are currently on a enterprise or custom plan, contact us to change your plan.
        </Alert>
      ) : (
        <Div flex={{ justify: 'center', direction: 'column' }}>
          {!sorted_plans || subscription_details === undefined ? (
            <Div p={8} flex={{ justify: 'center' }}>
              <Loading />
            </Div>
          ) : (
            <>
              {subscription?.cancel_at &&
                new Date(subscription.cancel_at).getTime() > Date.now() && (
                  <Alert warning inline m={{ b: 4 }}>
                    You've previously cancelled your paid plan and have{' '}
                    {differenceInDays(new Date(subscription.cancel_at), new Date())} days left until
                    you will be downgraded to the Developer (free) Plan.
                  </Alert>
                )}
              <Grid gap={4}>
                <GridUnit size={1 / 4}></GridUnit>
                {sorted_plans.map((orb_plan, i) => {
                  const is_current_plan = current_plan_index === i;
                  const plan =
                    is_current_plan && show_legacy_plan ? subscription_details.plan : orb_plan;
                  const subscription_plan_fixed_price = Number(
                    subscription_details.plan.prices.find(
                      (price) =>
                        price.price_type === 'fixed_price' && price.fixed_price_quantity === 1,
                    )?.unit_config?.unit_amount || 0,
                  );
                  const price =
                    is_current_plan && show_legacy_plan
                      ? subscription_plan_fixed_price
                      : orb_plan.fixed_price;
                  const plan_name = plan.name.split(' ')[0];
                  return (
                    <GridUnit size={1 / 4} key={plan.id}>
                      <Div>
                        <Div flex={{ align: 'center', gap: 2 }}>
                          <Text size="l">{plan_name}</Text>
                          {is_current_plan && show_legacy_plan && outdated_version && (
                            <Badge small monospace muted>
                              Legacy
                            </Badge>
                          )}
                        </Div>
                        <Text heading size="l" capitalize as="p" m={{ b: 4 }}>
                          {price === 0 ? 'Free' : `$${price} / month`}
                        </Text>
                        <Button.Permission
                          role="admin"
                          block
                          disabled={
                            (is_current_plan && !(outdated_version && !show_legacy_plan)) ||
                            !!isLoading
                          }
                          onClick={() => onPlanSelected(plan.id)}
                          primary={
                            (!!current_plan_index && i > current_plan_index) ||
                            (is_current_plan && outdated_version && !show_legacy_plan)
                          }
                          outline={
                            (!!current_plan_index && i < current_plan_index) ||
                            (is_current_plan && outdated_version)
                          }
                          icon={isLoading === plan.id ? 'loading' : undefined}>
                          {isLoading === plan.id
                            ? null
                            : is_current_plan
                              ? outdated_version && !show_legacy_plan
                                ? 'Update plan'
                                : 'Current Plan'
                              : current_plan_index && i < current_plan_index
                                ? 'Downgrade'
                                : 'Upgrade'}
                        </Button.Permission>
                      </Div>
                    </GridUnit>
                  );
                })}
              </Grid>
              <Divider m={{ y: 8 }} />
              <Text muted size="l">
                Usage
              </Text>
              {billing_metrics.events && (
                <FeatureRow
                  label="Events"
                  hightlight={highlighted_feature === 'events'}
                  cells={plansEventsRow}
                />
              )}
              {billing_metrics.discarded_requests && (
                <FeatureRow
                  label="Discarded Requests"
                  hightlight={highlighted_feature === 'request'}
                  cells={plansDiscardedRequestRow}
                />
              )}
              {billing_metrics.requests && (
                <FeatureRow
                  label="Requests"
                  hightlight={highlighted_feature === 'request'}
                  cells={plansRequestsRow}
                />
              )}
              {billing_metrics.attempts && (
                <FeatureRow
                  label="Attempts"
                  hightlight={highlighted_feature === 'request'}
                  cells={plansAttemptsRow}
                />
              )}
              <Text muted size="l" m={{ t: 8 }}>
                Features
              </Text>
              <FeatureRow
                label="Retention"
                hightlight={highlighted_feature === 'retention_days'}
                cells={sorted_plans.map(({ metadata }, i) => {
                  const retention_days = getPlanFeature(i, metadata, 'retention_days');
                  return {
                    text: `${retention_days} ${pluralize(Number(retention_days), 'Days')}`,
                  };
                })}
              />
              <FeatureRow
                label="# of Users"
                hightlight={highlighted_feature === 'max_users'}
                cells={sorted_plans.map(({ metadata }, i) => {
                  const feature = getPlanFeature(i, metadata, 'max_users');
                  return {
                    text: `${feature} ${pluralize(Number(feature), 'User')}`,
                  };
                })}
              />
              <FeatureRow
                label="Integrations (ie: Slack, Datadog)"
                hightlight={highlighted_feature === 'all_integrations'}
                cells={sorted_plans.map(({ metadata }, i) => {
                  const features = getPlanFeature(i, metadata, 'features');
                  return {
                    text: features.includes('all_integrations'),
                  };
                })}
              />
              <FeatureRow
                label="Metrics & Export"
                hightlight={highlighted_feature === 'metrics'}
                cells={sorted_plans.map(({ metadata }, i) => {
                  const features = getPlanFeature(i, metadata, 'features');
                  return {
                    text: features.includes('metrics'),
                  };
                })}
              />
              <FeatureRow
                label="Read-Only (Viewer) Role"
                hightlight={highlighted_feature === 'viewer_role'}
                cells={sorted_plans.map(({ metadata }, i) => {
                  const features = getPlanFeature(i, metadata, 'features');
                  return {
                    text: features.includes('viewer_role'),
                  };
                })}
              />
              <StyledCard raised m={{ t: 18 }} p={4}>
                <Div flex={{ justify: 'space-between' }}>
                  <Div>
                    <Div flex={{ gap: 1, align: 'center' }}>
                      <Text heading size="l" m={{ b: 0 }}>
                        Scale plan
                      </Text>
                      <Badge m={{ l: 1 }} primary subtle small monospace>
                        Enterprise
                      </Badge>
                    </Div>
                    <Text muted>
                      Volume discounts, support, custom features, OIDC/SAML authentication, and
                      more.
                    </Text>
                  </Div>
                  <Div flex={{ align: 'center' }}>
                    <Button outline primary onClick={showChat} icon="chat">
                      Contact us
                    </Button>
                  </Div>
                </Div>
              </StyledCard>
            </>
          )}
        </Div>
      )}
    </Container>
  );
};

export default OrganizationPlans;
